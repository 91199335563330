import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function Error() {
    useEffect(() => {
        document.title = '404 Page Not Found';
    });

    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <h1 className="mb-5 display-1 fw-bold">404 <span className="text-danger">Page Not Found</span></h1>
                <img src="./assets/under-construction.gif" className="mb-5 rounded img-fluid" alt="Under Construction GIF"/>
                <h3 className="my-2 text-center fw-light custom-font">This website is still under construction.</h3>
                <h3 className="my-2 text-center fw-light custom-font">Come back later!</h3>
                <h2 className="mt-5 text-center fw-bold custom-font">For now, you can check out our <Link className="text-decoration-none" to='/menu'>menu</Link>!</h2>
            </div>
        </div>
    );
}